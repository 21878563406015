import React from "react"
import { graphql } from "gatsby"
import Loadable from "@loadable/component"
import Breadcrumbs from "~/ui/compositions/navigation/breadcrumb"
import Page from "~/models/page"
import CasinoCollection from "~/models/casino-collection"

import SEO from "~/ui/helpers/seo"
import Frame from "~/ui/layout/frame"
import CasinoToplistBlock from "~/ui/compositions/casino/toplist"
import CardGridBlock from "~/ui/compositions/generic/card-grid"
import CollapsibleListBlock from "~/ui/compositions/generic/collapsible-list"
import PageCoverBlock from "~/ui/compositions/generic/page-cover"
import ParagraphsBlock from "../compositions/generic/paragraphs"

import {
  LiveCasinoListQuery,
  ContentfulPage,
  ContentfulCasinoCollection,
} from "../../../types/graphql-types"

type Props = {
  data: LiveCasinoListQuery
}

function LiveCasinoPage({ data }: Props) {
  const Analytics = Loadable(() => import("../helpers/analytics"))
  const page = new Page(data.contentfulPage as ContentfulPage)
  const casinoCollection = new CasinoCollection(
    data.contentfulCasinoCollection as ContentfulCasinoCollection
  )

  return (
    <Frame>
      <Analytics page={page} />
      <React.Fragment>
        <SEO page={page} />
        {page.blocks.map(block => (
          <React.Fragment key={block.id}>
            {block.name == "PageCover" && 
            <React.Fragment>
              <PageCoverBlock {...block.props} />
                <div className="mb-2 mt-2">
                  <div className="relative justify-between md:flex items-center max-w-screen-xl mx-auto py-7 lg:py-0 px-4 md:px-10 xl:px-20">
                    <Breadcrumbs breadcrumbs={page.breadcrumbs} />
                  </div>
                </div>
              </React.Fragment>
            }
            {block.name == "CasinoToplist" && (
              <CasinoToplistBlock
                collection={casinoCollection}
                {...block.props}
              />
            )}
            {block.name == "Paragraphs" && <ParagraphsBlock {...block.props} />}
            {block.name == "CardGrid" && <CardGridBlock {...block.props} />}
            {block.name == "CollapsibleList" && (
              <CollapsibleListBlock {...block.props} />
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    </Frame>
  )
}

export default LiveCasinoPage

export const query = graphql`
  query LiveCasinoList {
    contentfulPage(url: { pathname: { eq: "/live-casinos/" } }) {
      ...PageFields
    }
    contentfulCasinoCollection(type: { eq: "live" }) {
      ...CasinoCollectionFields
    }
  }
`
